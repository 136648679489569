@font-face {
    font-family: Overpass-Black;
    src: url('Overpass-Black.ttf') format("truetype");
    font-weight: 400;
}

h1{
    font-family: Overpass-Black, serif;
    font-weight: 400;
}
.Container{
    margin-left: 50px;
    max-width: fit-content;
    height: 300px;
    background: radial-gradient(at top, hsl(213, 19%, 18%), hsl(216, 12%, 8%));
    border-radius: 25px;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#chooseRating {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.Container h1 {
    letter-spacing: -0.5px;
    color:white;
}

.Container p {
    color:white;
}
#submitButton {
    background-color: hsl(25, 97%, 53%);
    border-radius: 25px;
    border:none;
    font-size: 15px;
    color: white;
    width: 100%;
    height: 40px;
}
#chooseRating > div{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
#chooseRating div button{
    background-color:hsl(213, 19%, 18%);
    color: white;
    width: 40px;
    border:none;
    border-radius: 50% !important;
    aspect-ratio: 1;
}
#chooseRating div button:hover {
    background-color: hsl(25, 97%, 53%);
}

.active {
    background-color: hsl(217, 12%, 63%) !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.3;
}